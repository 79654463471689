<template>
  <div class="login-container">
    <div to="home" class="login-left">
      <div class="left-img"></div>
      <!-- <img v-lazy="login" alt="" /> -->
    </div>
    <div class="login-right">
      <div class="header">
        <router-link to="home">
          <img v-lazy="logo2" alt="" />
        </router-link>
        <h5>手机号/密码登陆</h5>
      </div>
      <el-form :model="form" :rules="rules" :show-message="true" :validate-on-rule-change="false" ref="form" v-loading="loading">
        <div class="form">
          <div class="input-box">
            <el-form-item prop="mobile">
              <el-input v-model="form.mobile" placeholder="输入手机号/账号" class="input" maxlength="15" show-word-limit></el-input>
            </el-form-item>
          </div>
          <div class="input-box" style="height: 60px">
            <el-form-item prop="password">
              <el-input type="password" v-model="form.password" placeholder="输入密码" class="input" maxlength="20" show-word-limit></el-input>
            </el-form-item>
          </div>
           <router-link to="/forget"><div class="forget" style="text-align: right;margin-top: 5px;color: rgba(0, 0, 0, 0.5);margin-bottom: 10px;">忘记密码?</div></router-link>
          <!-- <div class="input-box">
            <div class="code-box">
              <el-form-item prop="code" required style="width: 50%;">
                <el-input v-model="form.code" placeholder="短信验证码" class="input code-number" maxlength="6" show-word-limit @blur="codeBlur" @focus="codeFocus"></el-input>
              </el-form-item>
              <div v-show="showCode" class="send-code" @click.prevent="getShortMessageCode()" :disabled="!showCode">发送验证码</div>
              <span v-show="!showCode" class="send-code count">{{ count }} s</span>
            </div>
          </div> -->
          <div class="form-tips">新用户登陆即自动注册，表示已同意 <router-link to="/serviceAgreement" target="_blank">《平台服务协议》</router-link>与<router-link to="/privacyAgreement" target="_blank">《平台隐私协议》</router-link>
          </div>
          <button class="login-btn" @click="loginBtn" v-preventReClick>登录</button>
          <router-link to="/register"><div class="register" style="text-align: center;margin-top: 20px;color: rgba(0, 0, 0, 0.5);">注册</div></router-link>
        </div>
      </el-form>
      <!-- 第三方登录 -->
      <!-- <div class="anotherLogin">
        <span class="account-txt">第三方账号登陆</span>
        <div class="wx-box">
          <span class="scan-login-txt">微信扫码登录</span>
          <div class="wx-img" @click="wxLoginBtn">
            <img v-lazy="wxLogin" alt="微信" class="wxLogin" />
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { login, getCode } from '@/api/api'
export default {
  name: "login",
  components: {},
  data() {
    const validateRequire = (rule, value, callback) => {
      if (!value || value === '') {
        callback(new Error(rule.message))
      } else {
        callback()
      }
    }
    return {
      loginLeft: require("../assets/images/login-left.png"),
      logo2: require("../assets/images/logo2.png"),
      wxLogin: require("../assets/images/wx-login.png"),
      errorPhone: false,
      errorCode: false,
      showCode: true,
      phoneTips: '手机号错误，请重新输入',
      timer: 0,
      count: 0,
      redirect:'',
      reg: /^1[0-9]{10}$/,
      form: {
        mobile: '',
        code: ''
      },
      loading: false,
      rules: {
        mobile: [
          { validator: validateRequire, message: '请输入手机号/账号', trigger: 'blur' }
        ],
        code: [
          { validator: validateRequire, message: '请输入验证码', trigger: 'blur' }
        ],
        password: [
          { validator: validateRequire, message: '请输入密码', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    // 微信登录
    wxLoginBtn() {
      window.open(`https://api.metaio.cc/api/auth/wechat/oauth`);
      // this.$router.push({ path: "/wxLogin" });
    },
    // 失去焦点
    phoneBlur() {
      // 验证手机号
      // const reg = /^1[0-9]{10}$/
      if (!this.form.mobile) this.errorPhone = true;
      if (this.form.mobile.length <= 10 || !this.reg.test(this.form.mobile)) {
        this.errorPhone = true;
      }
    },
    // 获取焦点
    phoneFocus() {
      this.errorPhone = false
    },
    codeBlur() {
      if (!this.form.code) this.errorCode = true
    },
    codeFocus() {
      this.errorCode = false
    },
    // 获取验证码
    getShortMessageCode() {
      if (!this.form.mobile) {
        // this.errorPhone = true;
        // this.phoneTips = '手机号不可为空';
        this.$message.error('手机号不可为空')
        return;
      }
      if (!this.reg.test(this.form.mobile)) {
        // this.errorPhone = true;
        // this.phoneTips = '手机号错误，请重新输入';
        this.$message.error('手机号错误，请重新输入')
        return;
      }
      // 验证码倒计时
      if (!this.timer) {
        getCode({ mobile: this.form.mobile }).then(res => {
          if (res.code == 200) {
            this.count = 60;
            this.showCode = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= 60) {
                this.count--;
              } else {
                this.showCode = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    // 登录
    loginBtn() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('user/login', this.form).then(res => {
            this.loading = false
            if (res.code == 200) {
              if (this.redirect) {
                 this.$router.push({ path: this.redirect })
              } else {
                this.$router.push({ path: '/square' })
              }
            }else{
              this.$message.error(res.msg)
            }
          }).catch(_ => {
            this.loading = false
          })
        }
      })
    }
  },
  mounted() {
    this.$nextTick().then(res => {
      const query = this.$route.query
      this.redirect = query.redirect
      console.log(this.redirect)
    })
  }
}

</script>
<style scoped lang="scss">
@import '../assets/css/login.scss';

.left-img {
  background-image: url('../assets/images/login-left.png');
  background-size: cover;
  width: 100%;
  height: 100%;
}

::v-deep .el-input__inner {
  border: none;
  height: 65px;
}

::v-deep .input .el-input__inner {
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: inset 0.25rem 0.25rem 0.4375rem -0.1875rem rgb(27 27 27 / 25%);
  border-radius: 0.375rem;
}

::v-deep .el-form-item {
  margin: 0;
}

</style>
